<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider />

    <v-list
      dense
      nav
      class="list-avatar"
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="@/assets/logo-img-inv.png"
            max-height="50"
            contain
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider/>

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!--<template v-slot:append>
      <base-item
        :item="{
          title: $t('menu.settings'),
          icon: 'mdi-cog-outline',
          to: '/settings',
        }"
      />
    </template>-->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState, mapGetters
} from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard BETA',
        to: '/',
        permission: ['DASHBOARD', 'DASHBOARD_ADMIN']
      },
      {
        icon: 'mdi-clipboard-outline',
        title: 'Reports',
        to: '/reports',
        permission: 'REPORTS_PREVIEW'
      },
      {
        icon: 'mdi-clipboard-outline',
        title: 'Sterling Reports',
        to: '/sterling-reports',
        permission: 'STERLING_REPORTS'
      },
      {
        icon: 'mdi-clipboard-text',
        title: 'Eze Reports',
        to: '/eze-reports',
        permission: 'EZE_REPORTS'
      },
      {
        icon: 'mdi-file-chart-outline',
        title: 'Blotters',
        to: '/blotters'
      },
      {
        icon: 'mdi-face-agent',
        title: 'Support BETA',
        to: '/support'
      },
      {
        icon: 'mdi-file-pdf-box-outline',
        title: 'PDF Reports',
        to: '/pdf/reports',
        permission: 'PDF_REPORTS'
      },
      {
        icon: 'mdi-cloud-refresh',
        title: 'Library',
        to: '/library',
        permission: ['LIBRARY_PREVIEW', 'LIBRARY_ADMIN']
      },
      {
        icon: 'mdi-console',
        title: 'MNDB Tool BETA',
        to: '/mndb-tool',
        permission: 'MNDB_TOOL_TERMINAL'
      },
      {
        icon: 'mdi-closed-caption',
        title: 'Disabled Symbols',
        to: '/disabled-symbols',
        permission: 'MANAGE_SYSTEM_SETTINGS'
      },
      {
        title: 'Bonus Statement BETA',
        icon: 'mdi-currency-eur',
        to: '/bonus-statement',
        permission: 'MONTHLY_STATEMENT'
      },
      {
        title: 'Bonus Statement Admin BETA',
        icon: 'mdi-bank',
        to: '/bonus-statement-admin',
        permission: 'MANAGE_MONTHLY_STATEMENT'
      },
      {
        title: 'Manual Positions',
        icon: 'mdi-package',
        to: '/manual-positions',
        permission: 'MANUAL_POSITION'
      },
      {
        title: 'Notifications',
        icon: 'mdi-bell',
        to: '/notifications'
      },
      {
        icon: 'mdi-account-cog-outline',
        title: 'User Management',
        to: '/users',
        permission: 'LIB_ACCESS_MODIFY'
      },
      {
        icon: 'mdi-account',
        title: 'User Profile',
        to: '/profile'
      },
      {
        icon: 'mdi-cog-outline',
        title: 'System Settings',
        to: '/system-settings',
        permission: 'MANAGE_SYSTEM_SETTINGS'
      },
      {
        icon: 'mdi-diversify',
        title: 'System Versions',
        to: '/system-versions',
        permission: 'SYSTEM_VERSIONS'
      }
    ]
  }),
  mounted () {
  },
  computed: {
    ...mapState(['barColor', 'barImage']),
    ...mapGetters(['hasRoles', 'hasPermission']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      }
    },
    computedItems () {
      return this.items.map(this.mapItem).filter(item => item.permission ? this.hasPermission(item.permission) : true)
    },
    profile () {
      return {
        avatar: true,
        title: this.$t('menu.top.company')
      }
    }
  },
  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    }
  }
}
</script>
<style>
logo-img {

}
.list-avatar.v-list {
  padding: 0px 8px;
}
</style>
<style lang="sass">
  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
